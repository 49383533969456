


































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import {PhotoInterface} from '@/types/photoInterface';
import {vxm} from '@/store';
import BuildFormDataMixin from '@/mixins/BuildFormDataMixin';
import {required} from 'vuelidate/lib/validators';

const moreThan = (param) => (value) => value >= param;
const lessThan = (param) => (value) => value <= param;

@Component({
  components: {
    Modal,
  },
  validations: {
    picsForm: {
      name: {
        required,
      },
      salePrice: {
        required,
        moreThan: moreThan(1.99),
        lessThan: lessThan(49.99),
      },
    },
  },
})
export default class EditAlbum extends Mixins(BuildFormDataMixin) {
  picsForm: PhotoInterface | null = null;
  @Prop({default: null}) readonly album!: PhotoInterface;

  @Watch('album', {immediate: true, deep: true}) onAlbumChange() {
    this.picsForm = JSON.parse(JSON.stringify(this.album));
  }

  save() {
    this.$v.picsForm.$touch();
    if (!this.$v.picsForm.$invalid) {
      const params = {
        id: this.album._id,
        data: new FormData(),
      } as any;
      this.buildFormData(params.data, this.picsForm);
      vxm.model
        .updatePhoto(params)
        .then(() => {
          this.$emit('closed', this.picsForm);
        })
        .catch((error) => {
          return error;
        });
    }
  }

  close() {
    this.$emit('closed');
  }
}
