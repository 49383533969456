
































import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import {PhotoInterface} from '@/types/photoInterface';
import {vxm} from '@/store';
import Loader from '@/components/Loader.vue';
import ProgressBar from '@/components/ProgressBar.vue';

@Component({
  components: {
    ProgressBar,
    Modal,
    Loader,
  },
})
export default class AddPhotosToAlbum extends Vue {
  @Prop({default: null}) readonly album!: PhotoInterface;
  photoUrls: Array<string> = [];
  file: Array<File> = [];
  uploadInProgress = false;
  loadingProgress = 0;
  uploadError = false;
  showDropZone = false;

  get maxPhotos(): number {
    return 50 - this.album.images.length - this.photoUrls.length;
  }

  handleFileUpload() {
    const maxPhotos = this.maxPhotos;
    (this.$refs.file as any).files.forEach((file, index) => {
      if (index >= maxPhotos) {
        return;
      }
      this.createForm(file);
    });
    (this.$refs.file as any).value = '';
  }

  createForm(file: File) {
    this.file.push(file);
    const reader = new FileReader();
    reader.onload = (e: any) => {
      (this.photoUrls as Array<string>).push(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  removeImage(i: number) {
    if (!this.uploadInProgress) {
      this.file.splice(i, 1);
      this.photoUrls.splice(i, 1);
    }
  }

  async publish() {
    this.uploadInProgress = true;
    this.loadingProgress = 0;
    const data = JSON.parse(JSON.stringify(this.album));
    data.file = this.file;
    await vxm.model
      .uploadPhoto({
        data: data,
        uploadProgressCallback: (progressEvent) => {
          this.loadingProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        },
      })
      .then(
        () => {
          this.loadingProgress = 100;
        },
        () => {
          this.uploadError = true;
        },
      );
    if (this.uploadError) {
      this.loadingProgress = 0;
    }
    this.uploadInProgress = false;
    if (!this.uploadError) {
      this.$notify({
        group: 'foo',
        title: 'Your Upload has finished.',
        duration: 5000,
        type: 'success',
        text:
          'The system will now generate and convert the files for faster access. Conversions might take a while to populate.',
      });
      this.close();
    }
  }

  dropHandler(ev: DragEvent) {
    if (ev.dataTransfer) {
      if (ev.dataTransfer.items) {
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
          if (ev.dataTransfer.items[i].kind === 'file') {
            const file = ev.dataTransfer.items[i].getAsFile() as File;
            if (file.type.split('/')[0] === 'image') {
              this.createForm(file);
            }
          }
        }
      } else {
        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
          const file = ev.dataTransfer.files[i];
          if (file.type.split('/')[0] === 'image') {
            this.createForm(file);
          }
        }
      }
    }
    this.showDropZone = false;
  }

  dragOverHandler() {
    this.showDropZone = true;
  }

  close() {
    this.$emit('closed');
  }
}
