


































import {Component, Mixins} from 'vue-property-decorator';
import {vxm} from '@/store';
import {PhotoInterface} from '@/types/photoInterface';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import EditAlbum from '@/components/modals/EditAlbum.vue';
import BuildFormDataMixin from '@/mixins/BuildFormDataMixin';
import AddPhotosToAlbum from '@/components/modals/AddPhotosToAlbum.vue';

@Component({
  components: {AddPhotosToAlbum, EditAlbum, ConfirmationModal},
})
export default class ModelAlbumDetail extends Mixins(BuildFormDataMixin) {
  album: PhotoInterface | null = null;
  imagesList: Array<string> = [];
  imagesThumbsList: Array<string> = [];
  showConfirm = false;
  showSettings = false;
  draggedPhoto = 0;
  showAddPhotos = false;

  mounted() {
    this.getData();
  }

  getData() {
    vxm.model
      .getPhoto(this.$route.params.id)
      .then((res) => {
        this.album = res.data;
        this.imagesList = JSON.parse(JSON.stringify(this.album?.images));
        this.imagesThumbsList = JSON.parse(JSON.stringify(this.album?.thumbnails));
      })
      .catch((error) => {
        return error;
      });
  }

  onDragStart(i: number) {
    this.draggedPhoto = i;
    setTimeout(() => {
      const index = this.imagesList.findIndex((x) => x === this.album?.images[i]);
      this.imagesList.splice(index, 1);
      this.imagesThumbsList.splice(index, 1);
    }, 100);
  }

  onDrop(i: number) {
    this.imagesList.splice(i, 0, this.album?.images[this.draggedPhoto] as string);
    this.imagesThumbsList.splice(i, 0, this.album?.thumbnails[this.draggedPhoto] as string);
    const placeholder = this.imagesList.findIndex((x) => x === 'placeholder');
    if (placeholder != -1) {
      this.imagesList.splice(placeholder, 1);
      this.imagesThumbsList.splice(placeholder, 1);
    }
    if (this.album) {
      this.album.images = JSON.parse(JSON.stringify(this.imagesList));
      this.album.thumbnails = JSON.parse(JSON.stringify(this.imagesThumbsList));
      vxm.model.uploadPhoto({data: this.album}).catch((error) => {
        return error;
      });
    }
  }

  onDragOver(i: number) {
    const placeholder = this.imagesList.findIndex((x) => x === 'placeholder');
    if (placeholder != -1) {
      this.imagesList.splice(placeholder, 1);
      this.imagesThumbsList.splice(placeholder, 1);
    }
    if (this.imagesList[i - 1] !== 'placeholder') {
      this.imagesList.splice(i, 0, 'placeholder');
      this.imagesThumbsList.splice(i, 0, 'placeholder');
    }
  }

  // in case drop out of container
  onDragend() {
    const placeholder = this.imagesList.findIndex((x) => x === 'placeholder');
    if (placeholder !== -1) {
      this.imagesList.splice(placeholder, 1);
      this.imagesThumbsList.splice(placeholder, 1);
      this.imagesList.splice(placeholder, 0, this.album?.images[this.draggedPhoto] as string);
      this.imagesThumbsList.splice(placeholder, 0, this.album?.thumbnails[this.draggedPhoto] as string);
      if (this.album) {
        this.album.images = JSON.parse(JSON.stringify(this.imagesList));
        this.album.thumbnails = JSON.parse(JSON.stringify(this.imagesThumbsList));
        vxm.model.uploadPhoto({data: this.album}).catch((error) => {
          return error;
        });
      }
    }
  }

  deleteAlbum(confirmed?: boolean) {
    this.showConfirm = false;
    if (confirmed && this.album) {
      vxm.model
        .deletePhoto(this.album._id)
        .then(() => {
          this.$router.push({name: 'model-albums'});
        })
        .catch((error) => {
          return error;
        });
    }
  }

  deletePhoto(i: number) {
    this.album?.images.splice(i, 1);
    this.album?.thumbnails.splice(i, 1);
    this.imagesList = JSON.parse(JSON.stringify(this.album?.images));
    this.imagesThumbsList = JSON.parse(JSON.stringify(this.album?.thumbnails));
    vxm.model.uploadPhoto({data: this.album}).catch((error) => {
      return error;
    });
  }

  updateSettings(data?: PhotoInterface) {
    this.showSettings = false;
    if (data) {
      this.album = JSON.parse(JSON.stringify(data));
    }
  }
  photosAdded() {
    this.showAddPhotos = false;
    this.getData();
  }
}
